import * as React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/Buttons"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Pattern from "../components/Layout/Pattern"
import { Calendar, DollarSign, ExternalLink } from "react-feather"
import useScroll from "../hooks/useScroll"
import teenBootcampImage from "../images/1x/teen-chef.png"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const scrollTo = useScroll()

  return (
    <Layout>
      <Helmet>
        <meta
          name="og:url"
          content="https://www.cmkculinary.org/teen-chef-bootcamp"
        />
        <meta name="og:image" content={teenBootcampImage} />
        <link
          rel="canonical"
          href="https://www.cmkculinary.org/teen-chef-bootcamp"
        />
      </Helmet>
      <SEO
        title="Teen Chef Bootcamp | CMK Culinary"
        description="In this bootcamp kids will learn Culinary skills such as Quick Breakfast Meals, Sandwich, Pastries, Nigeria Foods, 
        Hygiene, Drinks, Continentals Dishes etc."
      />
      <section className="container mx-auto pt-0 sm:py-24 flex flex-col md:flex-row items-start px-4">
        <div className="px-4 order-2 md:order-1 rounded-2xl max-w-2xl lg:p-12 space-y-8 shadow-2xl">
          <h1 className="text-4xl lg:text-7xl font-bold">
            <span className="font-playful text-gray-900 leading-2">
              Teen Chef <br className="hidden md:inline-block" />
              <span className="text-yellow-400 filter drop-shadow-lg">
                Bootcamp
              </span>
            </span>
          </h1>

          <div className="w-full max-w-xl select-none">
            <p
              className="leading-5 md:leading-auto
              text-lg md:text-xl text-gray-700 font-body
              flex mb-4 md:mb-8"
            >
              Make August an interesting month for your kids by giving them the
              oppurtunity to learn the art of cooking and etiquette.
            </p>
            <p className="text-sm md:text-lg text-gray-600">
              In this bootcamp kids will learn Culinary skills such as Quick
              Breakfast Meals, Sandwich, Pastries, Nigeria Foods, Hygiene,
              Drinks, Continentals Dishes etc.
            </p>
          </div>

          <div
            className="text-sm sm:text-base md:text-xl mb-4 text-gray-700 
            flex flex-col md:flex-row
            space-y-4 md:space-y-0 md:space-x-8"
          >
            <li className="inline-flex flex-col items-start">
              <span className="text-sm opacity-50">Classes Start</span>
              <span className="text-red-600  text-lg md:text-base">
                09th - 23rd&nbsp;August.
              </span>
            </li>

            <li className="inline-flex flex-col items-start">
              <span className="text-sm opacity-50">Participation Fee</span>
              <span className="font-bold text-lg md:text-base">
                NGN 50,000/child
              </span>
            </li>
          </div>

          <section className="mt-12 select-none">
            <div className="flex flex-col md:flex-row">
              <a
                href="https://forms.gle/eikRj2FVMhYMKG7F8"
                referrerPolicy="no-referrer"
              >
                <Button primary rounded className="w-full font-bold">
                  <ExternalLink class="mr-2" />
                  <span className="md:py-2 md:px-4">REGISTER YOUR KIDS</span>
                </Button>
              </a>
            </div>
            <p className="text-xs sm:text-sm mt-4 text-gray-600 max-w-lg mb-8">
              * This opportunity is for children within the ages of 8 - 17yrs
              only.
            </p>
          </section>
        </div>
        <div className="max-w-lg lg:pl-12 mb-12 -mx-4 md:mx-0 order-1">
          <img
            src={teenBootcampImage}
            alt="Teen Chef Summer Bootcamp"
            className="md:rounded-lg w-full"
          />
        </div>
      </section>
      <Pattern size={32} className="w-full h-24 bottom-0" />
    </Layout>
  )
}

export default IndexPage
